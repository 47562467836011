import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import StorygramGUI from './components/StorygramWrapper';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "filtering"
    }}>{`Filtering`}</h1>
    <p>{`It is possible to filter the events and actors of a Storygram in different ways.`}</p>
    <h2 {...{
      "id": "event-value-filter"
    }}>{`Event value filter`}</h2>
    <p>{`Filters all events outside the range defined in the variable filterEventValue.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`filterEventValue: [number | string | undefined, number | string | undefined];
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
`}</code></pre>
    <Playground __position={0} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'] },\n    { event: 1, actors: [\'d\', \'b\', \'e\'] },\n    { event: 2, actors: [\'d\', \'a\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    filterEventValue: [1, undefined],\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c']
      }, {
        event: 1,
        actors: ['d', 'b', 'e']
      }, {
        event: 2,
        actors: ['d', 'a']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        filterEventValue: [1, undefined]
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "group-size-filter"
    }}>{`Group size filter`}</h2>
    <p>{`Filters all events containing less or more actors than defined in the range in the variable filterGroupSize.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`filterGroupSize: [number | undefined, number | undefined];
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: [0, Number.MAX_SAFE_INTEGER]
`}</code></pre>
    <Playground __position={1} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'] },\n    { event: 1, actors: [\'d\', \'b\', \'e\'] },\n    { event: 2, actors: [\'d\', \'a\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    filterGroupSize: [3, undefined],\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c']
      }, {
        event: 1,
        actors: ['d', 'b', 'e']
      }, {
        event: 2,
        actors: ['d', 'a']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        filterGroupSize: [3, undefined]
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "grouping-amount-filter"
    }}>{`Grouping amount filter`}</h2>
    <p>{`Filters all actors that are grouped less or more times than defined in the range in the variable filterGroupAmt. The hidden actors are visualizable by clicking on the red number on top of the group.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`filterGroupAmt: [number | undefined, number | undefined];
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: [0, Number.MAX_SAFE_INTEGER]
`}</code></pre>
    <Playground __position={2} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'] },\n    { event: 1, actors: [\'d\', \'b\', \'e\'] },\n    { event: 2, actors: [\'d\', \'a\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    filterGroupAmt: [2, undefined],\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c']
      }, {
        event: 1,
        actors: ['d', 'b', 'e']
      }, {
        event: 2,
        actors: ['d', 'a']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        filterGroupAmt: [2, undefined]
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "event-contains-all-actors-filter"
    }}>{`Event contains all actors filter`}</h2>
    <p>{`Filters all events that don't include all actors specified in the variable mustContain.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`mustContain: string[];
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: []
`}</code></pre>
    <Playground __position={3} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'] },\n    { event: 1, actors: [\'d\', \'b\', \'e\'] },\n    { event: 2, actors: [\'d\', \'a\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    mustContain: [\'a\', \'b\'],\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c']
      }, {
        event: 1,
        actors: ['d', 'b', 'e']
      }, {
        event: 2,
        actors: ['d', 'a']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        mustContain: ['a', 'b']
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "event-contains-some-actors-filter"
    }}>{`Event contains some actors filter`}</h2>
    <p>{`// check if the event contains one of the given actors
shouldContain: string[];`}</p>
    <p>{`Filters all events that don't include at least one of the actors specified in the variable shouldContain.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`shouldContain: string[];
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: []
`}</code></pre>
    <Playground __position={4} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'] },\n    { event: 1, actors: [\'d\', \'b\', \'e\'] },\n    { event: 2, actors: [\'d\', \'a\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    shouldContain: [\'c\', \'e\'],\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c']
      }, {
        event: 1,
        actors: ['d', 'b', 'e']
      }, {
        event: 2,
        actors: ['d', 'a']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        shouldContain: ['c', 'e']
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "custom-event-filter"
    }}>{`Custom Event filter`}</h2>
    <p>{`Customizable filter callback that must return a boolean value, if true the event is visible, if false it is filtered.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`filterEventCustom: (event: Event) => boolean;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: () => true
`}</code></pre>
    <Playground __position={5} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'], type: \'one\' },\n    { event: 1, actors: [\'d\', \'b\', \'e\'], type: \'two\' },\n    { event: 2, actors: [\'d\', \'a\'], type: \'one\' },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    filterEventCustom: event => event.data.type === \'one\',\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c'],
        type: 'one'
      }, {
        event: 1,
        actors: ['d', 'b', 'e'],
        type: 'two'
      }, {
        event: 2,
        actors: ['d', 'a'],
        type: 'one'
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        filterEventCustom: event => event.data.type === 'one'
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "custom-actor-filter"
    }}>{`Custom Actor filter`}</h2>
    <p>{`Customizable filter callback that must return a boolean value, if true the actor is visible, if false it is filtered.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`filterActorCustom: (event: Event) => boolean;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: () => true
`}</code></pre>
    <Playground __position={6} __code={'<StorygramGUI\n  data={[\n    { actor: \'a\', start: 0, end: 2, type: \'one\' },\n    { actor: \'b\', start: 1, end: 2, type: \'one\' },\n    { actor: \'c\', start: 0, end: 3, type: \'two\' },\n  ]}\n  config={{\n    dataFormat: \'ranges\',\n    actorField: \'actor\',\n    startField: \'start\',\n    endField: \'end\',\n    filterActorCustom: actor => actor.data.type === \'one\',\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        actor: 'a',
        start: 0,
        end: 2,
        type: 'one'
      }, {
        actor: 'b',
        start: 1,
        end: 2,
        type: 'one'
      }, {
        actor: 'c',
        start: 0,
        end: 3,
        type: 'two'
      }]} config={{
        dataFormat: 'ranges',
        actorField: 'actor',
        startField: 'start',
        endField: 'end',
        filterActorCustom: actor => actor.data.type === 'one'
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      